import React from 'react';
// import {Link} from "gatsby";
// import 'bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// const $ = typeof window !== `undefined` ? require("jquery") : null
// const bootstrap =  typeof window !== `undefined` ? require('bootstrap'):null

// import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer } from
/// "mdbreact";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: {max: 4000, min: 3000},
    items: 5,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 3,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 2,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  },
};

class BeatBugs extends React.Component {
  /* constructor(props) {
        super(props);
    } */
  componentDidMount() {
    /* $(document).ready(function() {
            $(".link-services").hover(function() {
                    var imageId = $(this).attr("id");
                    imageId = "../../assets/images/beatbugs/Icons/Hover/" + imageId + '.svg';
                    $(this).find('img').attr("src", imageId);
                    $(this).css({
                        'color': '#fff',
                        'border-color':'red'
                    });
        
                },
                function() {
                    var imageId = $(this).attr("id");
                    imageId = "../../assets/images/beatbugs/Icons/" + imageId + '.svg';
                    $(this).find('img').attr("src", imageId);
                    $(this).css({
                        'color': ' #787E84',
                        'border-color': '#787E84'
                    });
                });
        }); */
  }

  render() {
    return (
      <>
        <section className="beatbugs-banner">
          <img
            className="mw-100"
            src={require('../../assets/images/beatbugs/beatbugs.png').default}
            alt=""
          />
        </section>
        <div>
          <section className="beatbugs-section-porfolio ">
            <div className="container">
              <div className="row d-flex justify-content-center align-items-center py-md-5 mx-0">
                <div className="col-lg-12">
                  <h1 className="heading">Beat Bugs</h1>
                  <p className="description">
                    The first interactive musical app from the Emmy Award-winning Beat Bugs series.
                    A sing-along, play-along musical experience that lets you take the best part of
                    the show with you. <br /> Made it to number 5 in the Best-selling Music app
                    category.
                  </p>
                  <div className="mt-5">
                    <a
                      id="Unity Game Development"
                      href="/services"
                      className="btn btn-local btn btn-outline-secondary mr-1 mb-2 link-services"
                      style={{}}
                    >
                      <img
                        width="35px"
                        height="35px"
                        src={
                          require('../../assets/images/beatbugs/Icons/Unity Game Development.svg')
                            .default
                        }
                        alt=""
                      />
                      Game Development
                    </a>
                    <a
                      id="2D-3D Game Art"
                      href="/services"
                      className="btn btn-local btn-outline-secondary mr-1 mb-2 btn link-services"
                    >
                      <img
                        width="35px"
                        height="35px"
                        src={
                          require('../../assets/images/beatbugs/Icons/2D-3D Game Art.svg').default
                        }
                        alt=""
                      />
                      2D/3D Game Art
                    </a>
                    <a
                      id="Project Management"
                      href="/services"
                      className="btn btn-local btn btn-outline-secondary mr-1 mb-2 link-services"
                    >
                      <img
                        width="35px"
                        height="35px"
                        src={
                          require('../../assets/images/beatbugs/Icons/Project Management.svg')
                            .default
                        }
                        alt=""
                      />
                      Project Management
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* / BeatBugs  application */}
          {/* BeatBugs client review */}
          <section className="beatbugs-client ">
            <div className="container ">
              <div className="row d-flex justify-content-center align-items-center py-md-5 py-sm-5 mx-0">
                <div className="col-lg-5">
                  <img
                    src={
                      require('../../assets/images/beatbugs/beatbugs-as-featuringtoday.png').default
                    }
                    alt=""
                  />
                </div>
                <div className="col-lg-7 px-5">
                  <img
                    className="mw-100"
                    src={require('../../assets/images/beatbugs/qoute.png').default}
                    alt=""
                  />
                  <p className="description">
                    The apps received favorable media attention. The first was on an important cable
                    news show; Google and Apple featured the second one... I give them an A-plus for
                    project management and communication.
                  </p>
                  <div className="box d-flex d-row">
                    <div style={{flex: 1}}>
                      <img
                        className=" img-fluid rounded-circle  user-img"
                        src={require('../../assets/images/beatbugs/shaan.png').default}
                        alt=""
                      />
                    </div>
                    <div style={{flex: 4}} className="pl-3 pt-lg-3 pt-md-3">
                      <p className=" user-name mb-0">Shaan Kandawalla</p>
                      <p className=" user-designation">Cofounder, Playdate Digital</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center align-items-center mx-0">
                <div className="col-lg-12  mt-2 ">
                  <p className="about-beatbugs ">
                    It included 4 classic songs made famous by the Beatles and performed by the Beat
                    Bugs.
                  </p>
                  <p className="about-beatbugs-description">
                    It featured 3D animated scenes and cascades of colorful animations triggered by
                    interacting with the environments and characters. There were over 100
                    interactive elements and sounds in the game.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* /BeatBugs client review */}
          {/* BeatBugs goal */}
          <section className="beatbugs-goal ">
            <div className="container">
              <div className="row d-flex justify-content-center  py-md-5 mx-0">
                <div className="col-lg-4 col-md-4 heading-margin">
                  <p className="goal mb-0 justify-content-start">The Goal</p>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 align-items-center">
                  <p className="heading">
                    To have a premium app showcasing five polished interactive scenes based on The
                    Beatles’ famous songs.
                  </p>
                  <p className="description">
                    Kids should be enticed to sing along and record their songs.
                    <br /> A key goal was to develop the art and design to a high enough quality to
                    satisfy brand expectations of the studio providing the intellectual property. It
                    was also a goal to achieve the quality that would result in selection for being
                    featured on the Apple and Google Play stores.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* /BeatBug work */}
          <section className="beatbugs-work ">
            <div className="container">
              <div className="row d-flex justify-content-center  py-md-5 mx-0 ">
                <div className="col-lg-4 col-md-4 col-sm-12 heading-margin">
                  <p className="goal mb-0">The Work</p>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 align-items-center">
                  <p className="heading">
                    Beat Bugs was Tintash’s first major 3D project in the Games space.
                  </p>
                  <p className="description">
                    It was made entirely in Unity. The initial two months were part of a discovery
                    phase used to experiment with the <i>Maya - Unity</i> pipeline to create an
                    interactive demo scene we could share with the clients. This was followed by
                    intense R&amp;D into key features such as robust voice recording and the
                    real-time highlighting of lyrics.
                  </p>
                  <p className="description">
                    We came across multifaceted challenges spread across design, art and
                    engineering.
                  </p>
                  <p className="challenge">Challenge #1</p>
                  <p className="description">
                    <span className="emphasize">
                      Our foremost design challenge was to convert high poly models provided by the
                      client into low poly models optimised for mobile.
                    </span>
                  </p>
                  <p className="description">
                    We did this by reducing poly count of characters sufficiently that it did not
                    compromise character appearance. We found that automated approaches did not
                    produce acceptable results so we manually and judiciously took up the intensive
                    task of adjusting the poly count in each object, gradually identifying best
                    practices for different shapes and sizes.
                  </p>
                </div>
                {/* images of the beat bugs */}
                <div className="col-lg-3 col-md-6 text-center mx-0 my-5">
                  <img
                    src={require('../../assets/images/beatbugs/low-poly-1.png').default}
                    alt=""
                  />
                  <p className="characters text-center  mt-3 pr-5">Low Poly</p>
                </div>
                <div className="col-lg-3 col-md-6 text-center mx-0 my-5">
                  <img
                    src={require('../../assets/images/beatbugs/high-poly-1.png').default}
                    alt=""
                  />
                  <p className="characters text-center  mt-2 pt-2">High Poly</p>
                </div>
                <div className="col-lg-3 col-md-6 text-center mx-0 my-5">
                  <img
                    src={require('../../assets/images/beatbugs/low-poly-2.png').default}
                    alt=""
                  />
                  <p className="characters text-center mt-3 pl-5">Low Poly</p>
                </div>
                <div className="col-lg-3 col-md-6 text-center mx-0 my-5">
                  <img
                    src={require('../../assets/images/beatbugs/high-poly-2.png').default}
                    alt=""
                  />
                  <p className="characters text-center  mt-2 pt-2 pl-3">High Poly</p>
                </div>
                {/* </div> */}
                {/* images of the beat bugs close */}
                {/* challenge 2 */}
                <div className="offset-lg-4 col-lg-8">
                  <p className="challenge">Challenge #2</p>
                  <p className="description">
                    <span className="empasize">
                      A complication we came across was the unstable recording and lyrics animation
                      implemented for the karaoke feature of the app.
                    </span>
                  </p>
                  <p className="description">
                    After a month of rigorous testing and several failed builds we were able to ship
                    the product with both features in a robust condition. We got the best results on
                    this by making changes directly in XCode since Unity did not handle this to our
                    satisfaction.
                  </p>
                </div>
                {/* challenge 2 */}
                <div className="container">
                  <div className="row d-flex justify-content-center align-items-center">
                    <img
                      className="my-4 mw-100 challenge-img"
                      src={require('../../assets/images/beatbugs/challenge.png').default}
                      alt=""
                    />
                  </div>
                </div>
                {/* Challenge  3 */}
                <div className="offset-lg-4 col-lg-8">
                  <p className="challenge">Challenge #3</p>
                  <p className="description">
                    <span className="empasize">
                      Lighting was tweaked many times to illuminate characters in the scene just
                      enough that they resemble characters from the show without overloading the
                      processor.
                    </span>
                  </p>
                  <p className="description">
                    The <i>Penny Lane</i> scene was very light intensive and had to go through
                    several rounds of client feedback before we could finalize the look and bake the
                    entire lighting system.
                  </p>
                </div>
                {/* Challenge 3  */}
                <div className="container">
                  <div className="row d-flex justify-content-center align-items-center">
                    <img
                      className="my-4 mw-100 challenge-img"
                      src={require('../../assets/images/beatbugs/challenge3.png').default}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* /BeatBug work */}
          {/* BeatBug goal */}
          <section className="beatbugs-goal result ">
            <div className="container">
              <div className="row d-flex justify-content-center  py-md-5 mx-0 ">
                <div className="col-lg-4 col-md-4 col-sm-12 heading-margin">
                  <p className="goal mb-0">The Result</p>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 align-items-center">
                  <p className="heading">
                    Beat Bugs was featured on the Apple Store in several countries upon release in
                    early 2018.
                  </p>
                  <p className="description">
                    In the American App Store, it was featured under New Apps We Love. It featured
                    in the Recommended section on the Canadian App Store.
                  </p>
                  <p className="description">
                    Within the American store, the app went as high as number 5 among Top Paid Apps
                    in the Music category and in the top 30 among all Kids apps within its first
                    week of launch.
                  </p>
                  <p className="description">
                    The app was subsequently launched on the Google Play Store and we are looking to
                    add additional levels to keep it fresh and engaging over the course of the year.
                  </p>
                  <p className="description">
                    The app has maintained a 4.5 average rating from users since its launch.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* /BeatBug goal  */}
          {/*  Section is for Carousel Images for large Screen  992px */}
          {/* <MDBContainer>
      <MDBCarousel
        activeItem={1}
        length={3}
        showControls={true}
        showIndicators={false}
        className="z-depth-1"
        slide
      >
        <MDBCarouselInner>
          <MDBCarouselItem itemId="1">
            <MDBView>
              <img
                className="d-block w-100"
                src={require('../../assets/images/beatbugs/01.png') } 
                alt="First slide"
              />
            </MDBView>
          </MDBCarouselItem>
          <MDBCarouselItem itemId="2">
            <MDBView>
              <img
                className="d-block w-100"
                src={require('../../assets/images/beatbugs/02.png') } 
                alt="Second slide"
              />
            </MDBView>
          </MDBCarouselItem>
          <MDBCarouselItem itemId="3">
            <MDBView>
              <img
                className="d-block w-100"
                src={require('../../assets/images/beatbugs/03.png') }
                alt="Third slide"
              />
            </MDBView>
          </MDBCarouselItem>
        </MDBCarouselInner>
      </MDBCarousel>
    </MDBContainer> */}
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr // means to render carousel on server-side.
            infinite
            autoPlay={this.props.deviceType !== 'mobile'}
            autoPlaySpeed={3000}
            keyBoardControl
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container py-2"
            removeArrowOnDeviceType={['tablet', 'mobile']}
            deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            <div>
              <img
                className="w-100"
                src={require('../../assets/images/beatbugs/01.png').default}
                alt=""
              />
            </div>
            <div>
              {' '}
              <img
                className="w-100"
                src={require('../../assets/images/beatbugs/02.png').default}
                alt=""
              />
            </div>
            <div>
              <img
                className="w-100"
                src={require('../../assets/images/beatbugs/03.png').default}
                alt=""
              />
            </div>
          </Carousel>

          {/* <section className="container-fluid my-5 carousel-section">     
          <div className="owl-carousel">
            <div className="item">
              <img className="w-100" src={require('../../assets/images/beatbugs/01.png') } />
            </div>
            <div className="item">
              <img className="w-100" src={require('../../assets/images/beatbugs/02.png') } />
            </div>
            <div className="item">
              <img className="w-100" src={require('../../assets/images/beatbugs/03.png') } />
            </div>
          </div>
        </section>
        <section className="container-fluid  my-3 carousel-mobile">
          <div className="container ">
            <div className="row justify-content-center align-items-center">
              <div className="col-12">
                <div id="carouselExampleControls" className="carousel slide " data-ride="carousel" data-interval={4000}>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img className="img-fluid" src={require('../../assets/images/beatbugs/01.png') } />
                    </div>
                    <div className="carousel-item ">
                      <img className="img-fluid" src={require('../../assets/images/beatbugs/02.png') } />
                    </div>
                    <div className="carousel-item">
                      <img className="img-fluid" src={require('../../assets/images/beatbugs/03.png') } />
                    </div>
                  </div>
                  <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true" />
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        </div>
      </>
    );
  }
}

export default BeatBugs;
